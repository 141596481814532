const colors = {
  primary: {
    // main color
    main: '#8A5360',
    main2: '#A66375',
    main3: '#763B49',
    // variants
    50: '#E3AEB8',
    100: '#C59DA6', 
    200: '#AF848E', 
    300: '#9C6A76',
    400: '#763B49',
    500: '#5B2431',
    600: '#4E1925',
  },
  secondary: {
    // main color
    main: '#D1B166',
    // variants
    50: '#EDD6A0',
    100: '#E7CB8A',
    200: '#DFC27C',
    300: '#C5A55A',
    400: '#B99643',
    500: '#B2903F',
    // text variant
    main2: '#C3A150',
    main3: '#7E6935',
  },
  error: '#b00020',
  white: '#ffffff',
  black: '#000000'
}

const fonts = {
  heading: 'Roboto, sans-serif',
  body: 'Roboto, sans-serif',
}

const fontSizes = {
  h1: '64px',
}

const lineHeights = {
  h1: '64px',
}

const sizes = {
  container: {
    xxl: '1400px',
  }
}

export default { colors, fonts, fontSizes, lineHeights, sizes, components: { Button: { baseStyle: { _focus: { outline: 0, boxShadow: 'none' } } }} };
