import '@fontsource/roboto'
import * as React from "react"
import type { GatsbyBrowser } from "gatsby"
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { Helmet } from "react-helmet"

import theme from './src/theme/default'

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => {
  return (
    <ChakraProvider theme={extendTheme(theme)}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Clara Braz Terapias</title>
        <link rel="canonical" href="https://clarabraz.com.br/" />
      </Helmet>
      {element}
    </ChakraProvider>
  )
}